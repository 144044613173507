<!-- eslint-disable vue/no-v-html -->
<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-card class="h-100">
          <!-- allProfileInfo.form -->
          <b-card-title class="mt-2">
            {{ $t('selectedCurrency_placeholder') }}:
          </b-card-title>
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label-for="currency"
                  :label="$t('token-or-coin')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('currency')"
                    vid="currency"
                    rules="required"
                  >
                    <v-select
                      v-model="selectedCurrency"
                      label="currency_name"
                      :options="currencies"
                      :clearable="false"
                      :placeholder="'test'"
                      :selectable="item => !item.disabled"
                      @input="selectedNetwork = []"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label-for="network"
                  :label="$t('Network')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Network')"
                    vid="network"
                    rules="required"
                  >
                    <v-select
                      id="network"
                      v-model="selectedNetwork"
                      label="name"
                      :options="networkOptions"
                      :placeholder="$t('Network')"
                      :disabled="networkOptions.length === 0"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="selectedCurrency.currency_name"
                cols="12"
              >
                <b-row class="mt-2">
                  <b-col
                    cols="12"
                    class="text-center my-3"
                  >
                    <p
                      class="font-italic text-danger"
                      v-html="$t('create_wallet_btn', { currency: selectedCurrency.currency_name })"
                    />
                    <b-button
                      variant="primary"
                      :disabled="Object.keys(selectedNetwork).length === 0"
                      @click="createWallet"
                    >
                      <span v-if="!createPending">
                        {{ $t('create_wallet') }}
                      </span>
                      <b-spinner
                        v-else
                        small
                        variant="light"
                        type="grow"
                      />
                    </b-button>
                  </b-col>
                  <!-- <b-col
                    cols="12"
                    class="mt-3 text-danger"
                  >
                    <p v-html="$t('create_wallet_description',{currency:selectedCurrency.currency_name})" />
                  </b-col> -->
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="6"
      >
        <b-card class="h-100">
          <div>
            <b-img
              :src="require('@/assets/images/wallet/wallet-img.png')"
              class="w-100"
            />
          </div>
        </b-card>
        <!-- <b-card>
          <div>
            <h5 class="text-danger mt-4">
              <span>
                <feather-icon
                  icon="AlertTriangleIcon"
                  size="20"
                />
              </span>
              <span>
                {{ $t('create_crypto_wallet_header') }}
              </span>
            </h5>
          </div>
          <div
            style="line-height:2;"
            v-html="$t('create_crypto_wallet_description')"
          />
        </b-card> -->
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BSpinner,
  BOverlay,
  BCardTitle,
  BImg,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import CustomerWalletApis from '@/services/apis/customer-wallet'

const customerWallet = new CustomerWalletApis()

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BSpinner,
    vSelect,
    BOverlay,
    BCardTitle,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      createPending: false,
      required,
      selectedCurrency: {},
      selectedNetwork: {},
      currencies: [],
    }
  },

  computed: {
    networkOptions() {
      return this.selectedCurrency?.networks || []
    },

  },

  mounted() {
    this.show = true
    customerWallet.getCurrencies().then(res => {
      // TODO: the coin configs must move to the backend
      customerWallet.getOmsCoins().then(omsTokens => {
        this.currencies = res.data.results.map(item => {
          omsTokens.data.results.forEach(omsToken => {
            if (item.currency_symbol === omsToken.nick_name) {
              // eslint-disable-next-line no-param-reassign
              item = { ...item, omsToken }
            }
          })
          // eslint-disable-next-line no-param-reassign
          item.currency_name = `${item.currency_name} (${item.currency_symbol})`
          return item
        }).filter(item => !item.omsToken || item.omsToken.canCreateWallet === '1')
      })
    }).finally(() => {
      this.show = false
    })
  },

  methods: {
    createWallet() {
      this.createPending = true
      customerWallet.createWallet({
        currency: this.selectedCurrency.currency_symbol,
        network: this.selectedNetwork.name,
      }).then(res => {
        this.$swal({
          title: res?.data.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
      }).finally(() => {
        this.createPending = false
        this.$router.push({ name: 'crypto-wallets' })
      })
    },
  },
}

</script>
